/* eslint-disable @typescript-eslint/no-explicit-any, react/no-danger */
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql, Script } from 'gatsby'
import { Helmet } from 'react-helmet'
import { debounce } from 'lodash'
import { useLocation } from '@reach/router'
import Layout from '../../components/layout'
import '../BlogTemplate.scss'
import './Template.scss'
import SEO from '../../components/seo'
import {
  generateBlogPostingSchema,
  generateBreadcrumbsSchema,
  generatePersonRohanSchema,
  generateProductSchema,
} from '../../lib/generate-schema'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import getBreadcrumbs from '../getBreadcrumbs'
import PopupDownload from '../../components/PopupDownload/PopupDownload'
import SideFooterBanners from './SideFooterBanners'
import BlogSearchInput from '../../components/Blogs/BlogSearchInput'
import BlogList from '../../components/Blogs/BlogList'
import useSearch from '../../hooks/useSearch'
import getOneLinkCMS from '../../utils/getOnelinkCMS'
import getFaqsCMS from '../../utils/getFaqsCMS'

import multiPageAuthor, {
  multiPageAuthor2,
} from '../../components/AuthorInfo/AuthorInfo-list'

import loadable from '@loadable/component'

const AppDownloadBanner = loadable(
  () => import('../../components/AppDownloadBanner/AppDownloadBanner')
)
const AuthorInfo = loadable(() =>
  pMinDelay(import('../../components/AuthorInfo/AuthorInfo'), 200)
)
const BlogSimilarArticles = loadable(() =>
  pMinDelay(import('../BlogSimilarArticles'), 200)
)
const BottomContent = loadable(() => pMinDelay(import('./BottomContent'), 200))
const ShareButtons = loadable(() =>
  pMinDelay(import('../../components/Share/Share'), 200)
)
import {
  pokerOneLinksBlogPage,
  rummyOneLinksBlogPage,
  rummyOneLinksOtherPage,
  pokerOneLinksPokerPage,
  rummyOneLinksRummyPage,
  pokerOneLinksOtherPage,
} from '../../components/one-links'
import { modifyHtmlBeforeRendering } from '../../utils/modifyATag'
import LinkLists from '../../components/Design2.0/common/LinkLists/LinkLists'
import { pokerBlogLinks } from '../../utils/poker-blog-links'
import PokerRooms from '../../components/Design2.0/common/PokerRooms/PokerRooms'
import pMinDelay from 'p-min-delay'
import PokerBlogsSideWidgets from '../../components/Blogs/side-widgets/PokerBlogsSideWidgets'
import RummyBlogsSideWidgets from '../../components/Blogs/side-widgets/RummyBlogsSideWidgets'

const RealMoneyGamesBlog: React.FC = (context: any) => {
  const { data, pageContext } = context
  const post = data.currPost
  const {
    prefix,
    similarArticles,
    allRealMoneyGamesBlogs,
    allRMGPokerRealMoneyBlogs,
    allRMGRummyRealMoneyBlogs,
    oneLink,
  } = pageContext
  post.html = modifyHtmlBeforeRendering(post.html, prefix)

  const showPokerWidgets = prefix === '/poker/rmg'
  const showRummyWidgets = prefix === '/rummy/rmg'

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [gameNameAutoBanners, setGameNameAutoBanners] = useState('')
  const [gameNameOtherBanners, setGameNameOtherBanners] = useState('')
  const [blogContext, setBlogContext] = useState<any | null>([])
  const [competitorSchema, setCompetitorSchema] = useState(false)

  const prefixes = prefix.split('/')
  const prefixCat = prefixes[prefixes.length - 2]
  const location = useLocation()
  const { title } = post
  const URL = location.href

  let rummyFormVisible = false
  let pokerFormVisible = false

  useEffect(() => {
    let gameNameAuto
    let gameNameOther
    // for categories that do not have Automated Banners, 'Poker' auto banners will be used.
    if (post.tags.some((e: { slug: string }) => e.slug === 'display-rummy')) {
      gameNameAuto = 'Rummy'
      gameNameOther = 'Rummy'
    } else if (
      post.tags.some(
        (e: { slug: string }) => e.slug === 'display-gambling-betting'
      )
    ) {
      gameNameAuto = 'Gambling'
      gameNameOther = 'Default'
    } else if (
      post.tags.some((e: { slug: string }) => e.slug === 'display-pool')
    ) {
      gameNameAuto = 'Pool'
      gameNameOther = 'Pool'
    } else if (
      post.tags.some(
        (e: { slug: string }) => e.slug === 'display-fantasy-games'
      )
    ) {
      gameNameAuto = 'Fantasy'
      gameNameOther = 'Default'
    } else if (
      post.tags.some((e: { slug: string }) => e.slug === 'display-carrom')
    ) {
      gameNameAuto = 'Carrom'
      gameNameOther = 'Carrom'
    } else if (
      post.tags.some((e: { slug: string }) => e.slug === 'display-trivia-games')
    ) {
      gameNameAuto = 'Trivia'
      gameNameOther = 'Default'
    } else if (
      post.tags.some((e: { slug: string }) => e.slug === 'display-poker')
    ) {
      gameNameAuto = 'Poker'
      gameNameOther = 'Poker'
    } else {
      gameNameAuto = 'Poker'
      gameNameOther = 'Default'
    }
    setGameNameAutoBanners(gameNameAuto)
    setGameNameOtherBanners(gameNameOther)
  }, [post.tags])

  // as we do not have side & footer banners for all display tags, we will use 'Default' as there,
  // and will be shown 'Poker' banners & Games testimonials & FAQs.
  const gameNameFiltered =
    gameNameOtherBanners === 'Default' ? 'Poker' : gameNameOtherBanners

  const breadcrumbs: Breadcrumb[] = getBreadcrumbs(prefix, post)

  const handlePlay = () => {
    setIsPopupOpen(true)
  }

  const getSchemaBrand = (posts?: any) => {
    switch (true) {
      case posts.slug === 'how-to-download-login-and-register-on-rummy-bindaas':
        return 'Rummy Bindass'
      case posts.slug === 'how-to-download-login-and-register-on-wow-rummy':
        return 'Wow Rummy'
      case posts.slug === 'how-to-download-register-login-rummy-mantra':
        return 'Rummy Mantra'
      case posts.slug === 'how-to-download-register-and-login-on-rummy-jacks':
        return 'Rummy Jacks'
      case posts.slug === 'how-to-download-register-and-log-in-to-rummy-health':
        return 'Rummy Health'
      default:
        return null
    }
  }

  if (prefix.includes('/poker/rmg')) {
    setBlogContext(allRMGPokerRealMoneyBlogs)
    pokerFormVisible = true
  } else if (prefix.includes('/rummy/rmg')) {
    setBlogContext(allRMGRummyRealMoneyBlogs)
    rummyFormVisible = true
  } else {
    setBlogContext(allRealMoneyGamesBlogs)
  }

  const [searchTerm, setSearchTerm] = useState('')
  const [blogsList, setBlogsList] = useState<any>([])
  const search = useSearch({
    searchName: 'title',
    documents: blogContext,
    indexFields: ['title'],
  })

  useEffect(() => {
    if (searchTerm) {
      setBlogsList(search.search(searchTerm).slice(0, 10))
    } else {
      setBlogsList([])
    }
  }, [searchTerm, search])

  useEffect(() => {
    const productSlug = [
      'how-to-download-login-and-register-on-rummy-bindaas',
      'how-to-download-login-and-register-on-wow-rummy',
      'how-to-download-register-login-rummy-mantra',
      'how-to-download-register-and-login-on-rummy-jacks',
      'how-to-download-register-and-log-in-to-rummy-health',
    ]
    if (productSlug.includes(post.slug)) {
      setCompetitorSchema(true)
    }
  }, [post.slug])

  const updateSearchTerm = debounce((s: string) => {
    setSearchTerm(s)
  }, 500)

  const [loaded, setLoaded] = useState(false)
  const [iframeCheck, setIframeCheck] = useState(false)
  const [levelOneSlug, setLevelOneSlug] = useState<any | null>(null)
  const [contentTop, setContentTop] = useState<any | null>(null)
  const [contentBottom, setContentBottom] = useState<any | null>(null)
  const [cTop1, setCTop1] = useState<any | null>(null)
  const [cTop2, setCTop2] = useState<any | null>(null)
  const [cBottom1, setCBottom1] = useState<any | null>(null)
  const [cBottom2, setCBottom2] = useState<any | null>(null)
  const [popupBanner1, setPopupBanner1] = useState(false)
  const [popupBanner2, setPopupBanner2] = useState(false)
  const [compareBanner, setCompareBanner] = useState(false)
  const oneLinkCMS = loaded ? getOneLinkCMS(post) : ''
  const faqDataCMS = loaded ? getFaqsCMS(post) : undefined
  const [contentFirstHalf, setContentFirstHalf] = useState('')
  const [contentSecondHalf, setContentSecondHalf] = useState('')
  const [contentThirdHalf, setContentThirdHalf] = useState('')
  const [contentLastHalf, setContentLastHalf] = useState('')

  const [updatedContent, setUpdatedContent] = useState('')

  const [searchVisible, setSearchVisible] = useState(false)

  const [bannerTitle, setBannerTitle] = useState('')
  const [bannerCoupon, setBannerCoupon] = useState('')
  const [bannerImage, setBannerImage] = useState('')
  const [customBanner, setCustomBanner] = useState(false)
  const couponCode = 'NEW300'

  const rmgGame = gameNameFiltered === 'Poker' || gameNameFiltered === 'Rummy'

  const insertTriviaBanner = (contents: string) => {
    const paragraphSplit = /(<p>\s*(?!<\/p>)\S.*?<\/p>)/gm

    const splitContent = contents.split(paragraphSplit)

    const splitIndex = Math.floor(splitContent.length)

    if (splitIndex > 30) {
      setContentFirstHalf(splitContent.slice(0, 4).join(' '))

      setContentSecondHalf(splitContent.slice(4, 10).join(' '))

      setContentThirdHalf(splitContent.slice(10, splitIndex - 5).join(' '))

      setContentLastHalf(
        splitContent.slice(splitIndex - 5, splitIndex).join(' ')
      )
    } else {
      setContentFirstHalf(splitContent.slice(0, 4).join(' '))

      setContentLastHalf(splitContent.slice(4, splitIndex).join(' '))
    }
  }

  useEffect(() => {
    const { html } = post
    const postContent = document.createElement('div')
    postContent.innerHTML = `${html}`
    const postIframes = postContent?.querySelectorAll('iframe')
    if (postIframes?.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < postIframes?.length; i++) {
        postIframes[i].loading = 'lazy'
      }
    }
    setUpdatedContent(postContent.innerHTML)
    setIframeCheck(true)
  }, [post])

  const popupCode = isPopupOpen ? (
    <PopupDownload
      close={() => {
        setIsPopupOpen(false)
      }}
      gameName={gameNameFiltered}
      apkLink={oneLinkCMS || oneLink}
      showPokerOnly={pokerFormVisible}
      showRummyOnly={rummyFormVisible}
      pokerLink={
        pokerFormVisible ? pokerOneLinksPokerPage : pokerOneLinksOtherPage
      }
      rummyLink={
        rummyFormVisible ? rummyOneLinksRummyPage : rummyOneLinksOtherPage
      }
    />
  ) : null

  useEffect(() => {
    const html = updatedContent

    const postContent = document.createElement('div')
    postContent.innerHTML = `${html}`

    const prodCard = postContent?.querySelectorAll('.kg-product-card')[0]
    if (prodCard) {
      ;(prodCard.querySelector('img') as HTMLImageElement).alt = 'getmega'
      const getCardTitle = prodCard?.querySelector(
        '.kg-product-card-title-container'
      )
      if (getCardTitle) {
        const getTitle = getCardTitle.textContent
        getCardTitle.innerHTML = `<div class="kg-product-card-title">${getTitle}</div>`
      }
    }

    // check for the new Top Banner snippet in the post html (https://getmega.atlassian.net/wiki/spaces/TT/pages/501645313/Custom+Top+Banner+for+Blog)
    if (postContent?.querySelector('#blog-top-banner')) {
      const topBannerTable =
        postContent?.querySelector('#blog-top-banner')?.nextElementSibling
      if (topBannerTable) {
        const rows = topBannerTable.getElementsByTagName('tr')
        if (rows.length) {
          setBannerTitle(rows[1].getElementsByTagName('td')[1].innerHTML)
          const bannerCouponText =
            rows[2].getElementsByTagName('td')[1].innerHTML
          setBannerCoupon(
            bannerCouponText.replace(
              '$coupon$',
              `<strong>${couponCode}</strong>`
            )
          )
          setBannerImage(rows[3].getElementsByTagName('td')[1].innerHTML)
        }
      }
      // indicating state that we have a custom top banner
      setCustomBanner(true)
    }

    // Logic:
    // Look for Compare Banner (data-competitor), if not found, show normal blog layout
    // if found, cut the html to two parts, top & bottom content
    // check in each parts for Automated Banner (data-auto-banner), if found, split the html to two parts again
    // save other contents as states, add Components in place of Banners.

    const getCompareBannerData =
      postContent?.querySelectorAll('[data-competitor]')[0]

    if (getCompareBannerData !== undefined) {
      // compare banner present
      const getSlugValue = getCompareBannerData.getAttribute('data-competitor')
      setLevelOneSlug(getSlugValue)
      const splitTag = /(<div data-competitor=)(.*?)(>)(.*?)(<)\/(div>)/

      // cTop : content top of Comparison Banner
      const cTop = postContent.innerHTML.split(splitTag).shift()
      setContentTop(cTop)

      // cBottom : content bottom of Comparison Banner
      const cBottom = postContent.innerHTML.split(splitTag).pop()
      setContentBottom(cBottom)

      if (
        postContent?.querySelectorAll('[data-auto-banner]')[0] &&
        cTop &&
        cBottom
      ) {
        // popupbanner present, we have top & bottom content split
        const splitTagAuto = /(<div data-auto-banner)(="")(>)(.*?)(<)\/(div>)/
        const cTopContent = document.createElement('div')
        cTopContent.innerHTML = cTop
        if (cTopContent.querySelectorAll('[data-auto-banner]')[0]) {
          // cTop1Content : content top of Automated Banner on the top part of Comparison Banner
          const cTop1Content = cTop.split(splitTagAuto).shift()
          setCTop1(cTop1Content)

          // cTop2Content : content bottom of Automated Banner on the top part of Comparison Banner
          const cTop2Content = cTop.split(splitTagAuto).pop()
          setCTop2(cTop2Content)

          setPopupBanner1(true)
        }
        const cBottomContent = document.createElement('div')
        cBottomContent.innerHTML = cBottom
        if (cBottomContent.querySelectorAll('[data-auto-banner]')[0]) {
          // cBottom1Content : content top of Automated Banner on the bottom part of Comparison Banner
          const cBottom1Content = cBottom.split(splitTagAuto).shift()
          setCBottom1(cBottom1Content)
          // cBottom2Content : content bottom of Automated Banner on the bottom part of Comparison Banner
          const cBottom2Content = cBottom.split(splitTagAuto).pop()
          setCBottom2(cBottom2Content)
          if (cBottom2Content) {
            insertTriviaBanner(cBottom2Content)
            setPopupBanner2(true)
          }
        }
      } else {
        insertTriviaBanner(contentBottom)
      }

      setCompareBanner(true)
    } else {
      insertTriviaBanner(updatedContent)
    }
    setLoaded(true)
  }, [contentBottom, post, updatedContent, iframeCheck])

  useEffect(() => {
    const postContents = document.getElementById('post-contents')

    if (postContents) {
      postContents.addEventListener('click', function (event) {
        if (event.target.id === 'table') {
          if (event.target.classList.contains('open')) {
            event.target.classList.remove('open')
          } else {
            event.target.classList.add('open')
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    const faqContents = document?.getElementById('FAQ')
    const otherSegment = document.getElementById('quick-links')
    if (!faqContents) {
      otherSegment?.classList.add('pTop')
    }
  }, [])

  const contentBeforeCompareBanner = (
    <>
      {cTop1 && cTop2 ? (
        <>
          <div
            className="top-content-before-popup"
            dangerouslySetInnerHTML={{ __html: cTop1 }}
          />
          {rmgGame && (
            <AppDownloadBanner
              rummyLink={rummyOneLinksBlogPage}
              pokerLink={pokerOneLinksBlogPage}
              gameType={gameNameFiltered}
            />
          )}
          <div
            className="top-content-after-popup"
            dangerouslySetInnerHTML={{ __html: cTop2 }}
          />
        </>
      ) : (
        <div
          className="top-content-no-popup"
          dangerouslySetInnerHTML={{ __html: contentTop }}
        />
      )}
    </>
  )

  const contentAfterCompareBanner = (
    <>
      {cBottom1 && cBottom2 ? (
        <>
          <div
            className="bottom-content-before-popup"
            dangerouslySetInnerHTML={{ __html: cBottom1 }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: contentFirstHalf,
            }}
          />
          {!rmgGame && (
            <div className={contentSecondHalf ? 'd-block d-md-none' : ''}>
              <AppDownloadBanner
                rummyLink={rummyOneLinksBlogPage}
                pokerLink={pokerOneLinksBlogPage}
                gameType={contentSecondHalf ? 'Rummy' : 'Poker'}
              />
            </div>
          )}
          {contentSecondHalf ? (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: contentSecondHalf,
                }}
              />
              {!rmgGame && (
                <div className="d-block d-md-none">
                  <AppDownloadBanner
                    pokerLink={pokerOneLinksBlogPage}
                    gameType="Poker"
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {rmgGame && (
                <AppDownloadBanner
                  rummyLink={rummyOneLinksBlogPage}
                  pokerLink={pokerOneLinksBlogPage}
                  gameType={gameNameFiltered}
                />
              )}
            </>
          )}
          {contentThirdHalf && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: contentThirdHalf,
                }}
              />
              {rmgGame ? (
                <AppDownloadBanner
                  rummyLink={rummyOneLinksBlogPage}
                  pokerLink={pokerOneLinksBlogPage}
                  gameType={gameNameFiltered}
                />
              ) : (
                <AppDownloadBanner
                  pokerLink={pokerOneLinksBlogPage}
                  gameType="Poker"
                />
              )}
            </>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: contentLastHalf,
            }}
          />
          {rmgGame ? (
            <AppDownloadBanner
              rummyLink={rummyOneLinksBlogPage}
              pokerLink={pokerOneLinksBlogPage}
              gameType={gameNameFiltered}
            />
          ) : (
            <AppDownloadBanner
              rummyLink={rummyOneLinksBlogPage}
              gameType="Rummy"
            />
          )}
        </>
      ) : (
        <div
          className="bottom-content-no-popup"
          dangerouslySetInnerHTML={{ __html: contentBottom }}
        />
      )}
    </>
  )

  const blogPostLevel2 = compareBanner ? (
    <>
      {contentBeforeCompareBanner}
      {contentAfterCompareBanner}
    </>
  ) : (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: contentFirstHalf,
        }}
      />
      {!rmgGame && (
        <div className={contentSecondHalf ? 'd-block d-md-none' : ''}>
          <AppDownloadBanner
            rummyLink={rummyOneLinksBlogPage}
            pokerLink={pokerOneLinksBlogPage}
            gameType={contentSecondHalf ? 'Rummy' : 'Poker'}
          />
        </div>
      )}
      {contentSecondHalf ? (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: contentSecondHalf,
            }}
          />
          {!rmgGame && (
            <div className="d-block d-md-none">
              <AppDownloadBanner
                pokerLink={pokerOneLinksBlogPage}
                gameType="Poker"
              />
            </div>
          )}
        </>
      ) : (
        <>
          {rmgGame && (
            <AppDownloadBanner
              rummyLink={rummyOneLinksBlogPage}
              pokerLink={pokerOneLinksBlogPage}
              gameType={gameNameFiltered}
            />
          )}
        </>
      )}
      {contentThirdHalf && (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: contentThirdHalf,
            }}
          />
          {rmgGame ? (
            <AppDownloadBanner
              rummyLink={rummyOneLinksBlogPage}
              pokerLink={pokerOneLinksBlogPage}
              gameType={gameNameFiltered}
            />
          ) : (
            <AppDownloadBanner
              pokerLink={pokerOneLinksBlogPage}
              gameType="Poker"
            />
          )}
        </>
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: contentLastHalf,
        }}
      />
      {rmgGame ? (
        <AppDownloadBanner
          rummyLink={rummyOneLinksBlogPage}
          pokerLink={pokerOneLinksBlogPage}
          gameType={gameNameFiltered}
        />
      ) : (
        <AppDownloadBanner
          rummyLink={rummyOneLinksOtherPage}
          gameType="Rummy"
        />
      )}
    </>
  )

  const sidebarLayout = (
    <div className="sidebar-wrap">
      {!showPokerWidgets && !showRummyWidgets && (
        <SideFooterBanners
          position="side"
          gameType={gameNameOtherBanners}
          openPopup={handlePlay}
        />
      )}
      {showPokerWidgets && <PokerBlogsSideWidgets />}
      {showRummyWidgets && <RummyBlogsSideWidgets />}
    </div>
  )

  const Level2BottomContent = (
    <div className="level2-bottom-content">
      <BottomContent
        game={gameNameOtherBanners}
        faqsType={faqDataCMS && faqDataCMS.length > 0 ? faqDataCMS : undefined}
      />
    </div>
  )

  const minReadingTime = 4

  const postMeta = (
    <>
      <div className="post-meta">
        <span>{post.updated_at}</span>
        <span>
          {post.reading_time <= minReadingTime
            ? minReadingTime
            : post.reading_time}{' '}
          min read
        </span>
      </div>
    </>
  )

  const blogSimilarArticles = (
    <BlogSimilarArticles
      post={post}
      prefix={prefix}
      fetchBlog={blogContext}
      similarArticles={similarArticles}
    />
  )

  const getFeaturedImage = (gameCat: string) => {
    switch (gameCat) {
      case 'poker':
        return (
          <StaticImage
            loading="eager"
            src="../../images/featured-images/poker-blogs.jpg"
            alt="getmega"
            width={730}
            height={311}
          />
        )
      case 'rummy':
        return (
          <StaticImage
            loading="eager"
            src="../../images/featured-images/rummy-blogs.jpg"
            alt="getmega"
            width={730}
            height={311}
          />
        )
      default:
        return (
          <StaticImage
            loading="eager"
            src="../../images/featured-images/other-blogs.jpg"
            alt="getmega"
            width={730}
            height={311}
          />
        )
    }
  }

  const postFeaturedImage = (
    <>
      {post.feature_image ? (
        <div className="article-poster-image" id="featureImage">
          <img
            src={post.feature_image}
            alt={post.title}
            width="730"
            height="311"
          />
        </div>
      ) : (
        <div id="featureImage">{getFeaturedImage(prefixCat)}</div>
      )}
    </>
  )

  // adding custom top banner if present, otherwise use regular Featured Images.
  const topBanner = customBanner ? (
    <div className="article-poster-image">
      <div className="article-custom-banner">
        <div dangerouslySetInnerHTML={{ __html: bannerImage }} />
        <div className="poster-image-texts">
          <h2 dangerouslySetInnerHTML={{ __html: bannerTitle }} />
          <p dangerouslySetInnerHTML={{ __html: bannerCoupon }} />
        </div>
      </div>
    </div>
  ) : (
    postFeaturedImage
  )

  useEffect(() => {
    const newPostData = document.getElementById('post-contents')
    const prodIframes = newPostData?.querySelectorAll('iframe')
    if (prodIframes?.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < prodIframes?.length; i++) {
        // adding lazy load for iframes
        prodIframes[i].loading = 'lazy'
      }
    }
  }, [loaded])

  const postLayout = (
    <>
      <div className="level2-blog">
        {searchVisible && (
          <div className="blog-list-wrap">
            <Container>
              <BlogSearchInput onChange={s => updateSearchTerm(s)} />
              <div className="blogs-list">
                {blogsList.map((b: any) => {
                  return (
                    <BlogList
                      key={b.id}
                      title={b.title}
                      slug={b.slug}
                      tags={b.tags}
                      prefix={b.prefix}
                      reading_time={b.reading_time}
                      html={b.html}
                      author={b.primary_author.name}
                      feature_image={b.feature_image}
                      updated_at={''}
                    />
                  )
                })}
              </div>
            </Container>
          </div>
        )}
        <div className="blog">
          <Container>
            <Row>
              <Col lg={8}>
                <div className="blogPostContent">
                  <h1>{post.title}</h1>
                  {postMeta}
                  {topBanner}
                  <div id="post-contents">
                    {loaded && iframeCheck ? (
                      blogPostLevel2
                    ) : (
                      <div className="skelton-content-section">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: post.html,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <>
                  {post.primary_author.name === 'Shahla Jabbeen' ? (
                    <AuthorInfo Author={multiPageAuthor2} />
                  ) : (
                    <AuthorInfo Author={multiPageAuthor} />
                  )}
                </>

                <div className="post-like-languages">
                  <ShareButtons title={title} url={URL} />
                </div>
              </Col>
              <Col>{sidebarLayout}</Col>
            </Row>
            <Row>
              <Col lg={8}>{blogSimilarArticles}</Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container>
        <Row>
          <SideFooterBanners
            position="bottom"
            gameType={gameNameOtherBanners}
            openPopup={handlePlay}
          />
        </Row>
      </Container>
      {prefix.includes('/poker/rmg') ? '' : Level2BottomContent}
    </>
  )

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={oneLinkCMS || oneLink}
      blogPage
      showSearchFunc={setSearchVisible}
      showSearchBar={searchVisible}
      showPokerForm={pokerFormVisible}
      showRummyForm={rummyFormVisible}
      pokerOneLink={pokerFormVisible ? pokerOneLinksPokerPage : undefined}
      rummyOneLink={rummyFormVisible ? rummyOneLinksRummyPage : undefined}
    >
      <SEO
        blogPostingSchema={generateBlogPostingSchema({
          articleBody: post.html,
          author: post.primary_author.name,
          createdAt: post.created_at,
          description: post.meta_description,
          modifiedAt: post.updated_at,
          publishedAt: post.published_at,
          title: post.title,
          image: post.feature_image
            ? post.feature_image
            : data.site.siteMetadata.siteUrl + data.defaultOGImage.publicURL,
          url: `${data.site.siteMetadata.siteUrl}${prefix}/${post.slug}`,
        })}
        productSchema={
          competitorSchema &&
          generateProductSchema({
            product: getSchemaBrand(post),
          })
        }
        personSchema={generatePersonRohanSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        description={post.meta_description}
        title={post.meta_title || post.title}
        url={`${data.site.siteMetadata.siteUrl + prefix}/${post.slug}`}
      />
      <Helmet
        meta={[
          {
            name: 'twitter:image',
            content: post.feature_image
              ? post.feature_image
              : data.site.siteMetadata.siteUrl + data.defaultOGImage.publicURL,
          },
          {
            property: 'og:image',
            content: post.feature_image
              ? post.feature_image
              : data.site.siteMetadata.siteUrl + data.defaultOGImage.publicURL,
          },
        ]}
      >
        <link rel="preconnect" href="https://getmega.ghost.io" />
        <link rel="dns-prefetch" href="https://getmega.ghost.io" />
      </Helmet>
      {rummyFormVisible && (
        <Helmet>
          <script>
            {`
            document.addEventListener('DOMContentLoaded', () => {
              /** init gtm after 1500 seconds - this could be adjusted */
              setTimeout(initGTM, 1500);
            });
            document.addEventListener('scroll', initGTMOnEvent);
            document.addEventListener('mousemove', initGTMOnEvent);
            document.addEventListener('touchstart', initGTMOnEvent);
            function initGTMOnEvent(event) {
              initGTM();
              event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
            }
            function initGTM() {
              if (window.gtmDidInit) {
                return false;
              }
              window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.async = true;
              // ensure PageViews is always tracked (on script load)
              script.onload = () => {
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'AW-718511341');
              };
              script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-718511341';
              document.head.appendChild(script);
            }
          `}
          </script>
        </Helmet>
      )}

      <article id="Post" className="post-level2">
        <>{postLayout}</>
      </article>

      {popupCode}
    </Layout>
  )
}

export default RealMoneyGamesBlog

export const postQuery = graphql`
  query ($slug: String!) {
    currPost: ghostPost(slug: { eq: $slug }) {
      title
      slug
      feature_image
      html
      excerpt
      custom_excerpt
      meta_title
      meta_description
      published_at
      created_at
      reading_time
      updated_at(formatString: "MMMM DD, YYYY")
      custom_template
      tags {
        slug
      }
      primary_author {
        name
      }
    }
    defaultOGImage: file(
      relativePath: { eq: "share-blog-post/getmega-default.png" }
    ) {
      publicURL
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
