import { useStaticQuery, graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { useMemo } from 'react'

type BannerPosition = 1 | 2 | 3

export interface PokerBanner {
  img: IGatsbyImageData
  position: BannerPosition
}

const usePokerBanners = (): PokerBanner[] => {
  const data = useStaticQuery(graphql`
    {
      side1: file(relativePath: { eq: "learn-poker-banners/newside_1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      side2: file(relativePath: { eq: "learn-poker-banners/newside_2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      side3: file(relativePath: { eq: "learn-poker-banners/newside_3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      side4: file(relativePath: { eq: "learn-poker-banners/newside_4.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      side5: file(relativePath: { eq: "learn-poker-banners/newside_5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      side6: file(relativePath: { eq: "learn-poker-banners/newside_6.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom1: file(relativePath: { eq: "learn-poker-banners/newbottom_1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom2: file(relativePath: { eq: "learn-poker-banners/newbottom_2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom3: file(relativePath: { eq: "learn-poker-banners/newbottom_3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom4: file(relativePath: { eq: "learn-poker-banners/newbottom_4.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom5: file(relativePath: { eq: "learn-poker-banners/newbottom_5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom6: file(relativePath: { eq: "learn-poker-banners/newbottom_6.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const pokerBanners: PokerBanner[] = useMemo(
    () => [
      {
        img: data.side1.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.side2.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.side3.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.side4.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.side5.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.side6.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.bottom1.childImageSharp.gatsbyImageData,
        position: 2,
      },
      {
        img: data.bottom2.childImageSharp.gatsbyImageData,
        position: 2,
      },
      {
        img: data.bottom3.childImageSharp.gatsbyImageData,
        position: 2,
      },
      {
        img: data.bottom4.childImageSharp.gatsbyImageData,
        position: 2,
      },
      {
        img: data.bottom5.childImageSharp.gatsbyImageData,
        position: 2,
      },
      {
        img: data.bottom6.childImageSharp.gatsbyImageData,
        position: 2,
      },
    ],
    [data]
  )

  return pokerBanners
}

export default usePokerBanners
